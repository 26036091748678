import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QrComponent } from './qr/qr.component';
import { SearchComponent } from './search/search.component';
import { TasteMapComponent } from './taste-map/taste-map.component';


const routes: Routes = [
  { path: 'search/:query', component: SearchComponent },
  { path: 'map/:username', component: TasteMapComponent },
  { path: 'map', redirectTo: "map/" },
  { path: 'referral/:referral', component: QrComponent },
  { path: '**', redirectTo: "search/" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
