import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import * as algoliasearch from 'algoliasearch';
import { history as historyRouter } from 'instantsearch.js/es/lib/routers';
import { combineLatest, from, Observable, of } from 'rxjs';
import { filter, flatMap, map, startWith } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ReferralCache, SearchResult } from './types';

interface TasteUserData {
  vanity: null | {
    username: string | null;
    photo: DocumentReference | null;
    display_name: string | null;
  }
  photo_url: string;
  display_name: string;
}

export class TasteUser {
  photo: string | DocumentReference;
  name: string;
  ref: DocumentReference;
  username: string;
  constructor(snapshot: firebase.firestore.QueryDocumentSnapshot) {
    const data = snapshot.data() as TasteUserData;
    this.photo = data.vanity.photo || data.photo_url;
    this.name = data.vanity.display_name || data.display_name;
    this.username = data.vanity.username || "";
    this.ref = snapshot.ref;
  }
}

export enum Resolution {
  full,
  medium,
  thumbnail,
}

@Injectable({
  providedIn: 'root'
})
export class SearchResultService {
  async userFromUsername(username: string): Promise<TasteUser> {
    if (username.length == 0) {
      return null;
    }
    if (username.startsWith('users/')) {
      return new TasteUser(await this.firestore.doc<TasteUserData>(username).get().toPromise());
    }
    const result = await this.firestore.collection<TasteUser>('users', (ref) => ref.where('vanity.username', '==', username).limit(1)).get().toPromise();
    if (result.empty) {
      return null;
    }
    return new TasteUser(result.docs[0]);
  }
  private cache = new Map<String, Observable<SearchResult>>();
  private pathCache = new Map<String | DocumentReference, Observable<string>>();
  credentials: Observable<firebase.auth.UserCredential>;
  index: algoliasearch.Index;
  reviewIndex: algoliasearch.Index;
  discoverIndex: algoliasearch.Index;
  algoliaConfig;
  constructor(private storage: AngularFireStorage, private auth: AngularFireAuth,
    private firestore: AngularFirestore, ) {
    this.credentials = from(this.auth.auth.signInAnonymously()).pipe(filter((i) => !!i));
    const algolia = environment.algolia;
    algolia.routing = {
      router: historyRouter({
        windowTitle(params) {
          const query = (params || {}).query;
          if (!query) {
            return "Taste Deals";
          }
          return `Search results for ${query}`;
        },

        createURL({ routeState }) {
          if (!routeState) {
            return;
          }
          if (!routeState.query) {
            return;
          }
          return `/search/${routeState.query}`;
        },

        parseURL({ location }) {
          if (!location.pathname.startsWith('/search')) {
            return;
          }
          return {
            query: location.pathname.match(/search\/(.*)$/)[1],
          };
        }
      }),

      stateMapping: {
        stateToRoute(uiState) {
          return {
            query: uiState.query || "",
          };
        },

        routeToState(routeState) {
          return {
            query: routeState.query || "",
          };
        }
      }
    };
    const client = algoliasearch(algolia.appId, algolia.apiKey);
    delete algolia.appId;
    delete algolia.apiKey;
    algolia.searchClient = client;
    this.algoliaConfig = algolia;
    this.index = environment.algolia.searchClient.initIndex(environment.algolia.indexName)
    this.reviewIndex = environment.algolia.searchClient.initIndex(environment.algolia.reviewIndexName)
    this.discoverIndex = environment.algolia.searchClient.initIndex('discover');
  }

  get(reference: string): Observable<SearchResult> {
    if (this.cache.has(reference)) {
      return this.cache.get(reference);
    }
    this.cache.set(reference, new Observable((observer) => {
      const objectID = `referral_link ${reference}`;
      this.index.getObject(objectID, (err, result: ReferralCache) => {
        observer.next(this.asSearchResult(result));
      });
    }));
    return this.get(reference);
  }

  asSearchResult(referralCache: ReferralCache): SearchResult {
    const searchResult = new SearchResult();
    searchResult.cache = referralCache;
    searchResult.userPhoto = this.resolve(referralCache.user_photo).toPromise();
    searchResult.reviewPhoto = this.resolve(referralCache.review_photo).toPromise();
    this.cache.set(searchResult.cache.reference, of(searchResult));
    return searchResult;
  }

  resolve(path: string | DocumentReference, resolution: Resolution = Resolution.thumbnail): Observable<string> {
    if ((path as DocumentReference).path) {
      if (this.pathCache.has(path)) {
        return this.pathCache.get(path);
      }
      this.pathCache.set(path, from((path as DocumentReference).get()).pipe(
        flatMap((p) => this.resolve(p.data().firebase_storage_path as string, resolution)),
        startWith('./assets/public/images/watermark.png'),
      ));
      return this.resolve(path);
    }
    path = path as string;
    if (path.startsWith('http')) {
      return of(path);
    }
    if (this.pathCache.has(path)) {
      return this.pathCache.get(path);
    }
    if (resolution !== Resolution.thumbnail) {
      const thumb = this.resolve(path, Resolution.thumbnail);
      const resPath = resolutionPath(path, resolution);
      // Prefer the hi-res version over lo-res.
      const result = combineLatest([from(this.storageDownloadUrl(resPath)), thumb]).pipe(map((l) => l[0] ? l[0] : l[1]));
      this.pathCache.set(resPath, result);
      return result;
    }
    const result = from(this.storageDownloadUrl(path)).pipe(startWith('./assets/public/images/watermark.png'));
    this.pathCache.set(path, result);
    // Seed the medium-res ahead of time.
    this.resolve(path, Resolution.full);
    return result;
  }

  async storageDownloadUrl(path: string): Promise<string> {
    console.log('getting', path);
    await this.credentials.pipe(filter((c) => !!c.user)).toPromise();
    const ref = this.storage.ref(path);
    const url = await ref.getDownloadURL().toPromise();
    return url;
  }
}

function resolutionPath(path: string, resolution: Resolution) {
  if (resolution === Resolution.full) {
    return path;
  }
  const dotIndex = path.lastIndexOf('.');
  const suffix = path.substring(dotIndex);
  const start = path.substring(0, dotIndex);
  const lastSlashIndex = start.lastIndexOf('/');
  const dir = start.substring(0, lastSlashIndex);
  const filename = start.substring(lastSlashIndex + 1);
  const size = resolution === Resolution.thumbnail ? 200 : 600;
  return `${dir}/thumbnails/${filename}_${size}x${size}${suffix}`;
}