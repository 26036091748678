import { Component } from '@angular/core';
import { SearchResultService } from '../search-result.service';


@Component({
  selector: 'search-root',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {
  constructor(private search: SearchResultService) { }

  get algolia() {
    return this.search.algoliaConfig;
  }
}
