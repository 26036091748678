import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, flatMap, map } from 'rxjs/operators';
import { SearchResultService } from '../search-result.service';
import { SearchResult, ReferralCache } from '../types';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-qr',
  templateUrl: './qr.component.html',
  styleUrls: ['./qr.component.scss']
})
export class QrComponent implements OnInit {
  qr: Observable<string>;
  searchResult: Observable<SearchResult>;
  private _searchResult: SearchResult;
  shortCode: Observable<String>;

  constructor(private functions: AngularFireFunctions,
    private route: ActivatedRoute,
    private results: SearchResultService,
    private analytics: AngularFireAnalytics,
  ) { }

  async ngOnInit() {
    this.searchResult = this.results.credentials.pipe(flatMap(_ => this.route.paramMap.pipe(
      flatMap((params: ParamMap) => {
        const referral = `referral_links/${params.get('referral')}`;
        this.analytics.logEvent('deal_referral_load', { ref: referral });
        return this.results.get(referral);
      }),
      filter((searchResult: SearchResult) => {
        this._searchResult = searchResult;
        return !!searchResult.cache;
      }),
    )));
    this.qr = this.searchResult.pipe(
      flatMap((searchResult: SearchResult) => {
        const referral = searchResult.cache.reference;
        const payload = { referral };
        const fn = 'generateQR';
        const data = { fn, payload };
        return this.functions.httpsCallable('tasteCall')(data)
      }),
      map(d => {
        return d.value.reference;
      })
    );
    this.shortCode = this.qr.pipe(
      flatMap((qr: string) => {
        const payload = { qr };
        const fn = 'generateQRShorthand';
        const data = { fn, payload };
        return this.functions.httpsCallable('tasteCall')(data);
      }),
      map(response => {
        return response.value.reference.split('/')[1];
      }),
    );
    this.analytics.logEvent('deal_referral_init');
  }

  get referralCache(): ReferralCache {
    return this._searchResult.cache;
  }

  get restaurantName(): String {
    return this._searchResult.cache.restaurant_name;
  }
  get dealDetail(): String {
    const deal = this._searchResult.cache.deal;
    if (!deal) {
      return "Error loading deal details :(";
    }
    if (!!deal.promotional_text) {
      return deal.promotional_text;
    }
    if (!!deal.discount_amount_percentage && deal.discount_amount_percentage > 0) {
      return `Get ${deal.discount_amount_percentage}% off your order!`;
    }
    if (!!deal.discount_amount_cents && deal.discount_amount_cents > 0) {
      return `Get \$${deal.discount_amount_percentage / 100} off your order!`
    }
    return "Error loading deal details :(";
  }

}
