import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SearchResultService, TasteUser } from '../search-result.service';
import { Subject, Observable } from 'rxjs';
import { flatMap, map, debounceTime, startWith, auditTime } from 'rxjs/operators';

interface User {
  username: string | null;
  reference: string;
  name: string | null;
  display: string;
}

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss']
})
export class UserSearchComponent implements OnInit {
  private terms = new Subject<string>();
  users: Observable<User[]>;
  @Output() selected = new EventEmitter<string>();

  constructor(private search: SearchResultService) { }
  keyword = 'username';

  ngOnInit() {
    this.users = this.terms.pipe(
      auditTime(500),
      flatMap(t => this.search.discoverIndex.search<User>({ query: t, tagFilters: ['user'] })),
      map(r => r.hits),
      debounceTime(500),
      startWith([] as User[]),
      map(r => {
        const display = (r: User): string => {
          if (r.username && r.username.length) {
            if (r.name && r.name.length) {
              return `${r.username}: ${r.name}`;
            }
            return r.username;
          }
          return r.name;
        }
        return r.map(r => {
          r.display = display(r);
          return r;
        });
      }),
    );
  }

  selectEvent(user: User) {
    this.selected.emit(user.username || user.reference);
  }

  onChangeSearch(val: string) {
    console.log('change', val);
    this.terms.next(val);
  }
}
