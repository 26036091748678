import { Observable } from 'rxjs';
import { InstantSearchConfig } from 'angular-instantsearch/instantsearch/instantsearch';

export interface Deal {
    discount_amount_cents: number;
    discount_amount_percentage: number;
    promotional_offer: string;
    promotional_text: string;
}
export interface Geoloc {
    lat: number;
    lng: number;
}

// Copied from taste_protos/protos/algolia.proto
// Cannot use JS generated files because they don't support fromJSON.
// Maybe we could write our own simple one...
export interface ReferralCache {
    dish_name: string;
    restaurant_name: string;
    review_text: string;
    user_name: string;
    user_username: string;
    user_photo: string;
    review_photo: string;
    deal: Deal;
    reference: string;
    _geoloc: Geoloc;
    objectID: string;
}

export class SearchResult {
    userPhoto: Promise<string>;
    reviewPhoto: Promise<string>;
    cache: ReferralCache;
}

export interface Environment {
    algolia: any;
    firebase: any;
    production: boolean;
}