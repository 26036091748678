import { Environment } from 'src/app/types';

export const environment: Environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCKxt0dAVGWyKybcsHQ76hXTfyz4uaf7HA',
    authDomain: 'koutavi.firebaseapp.com',
    databaseURL: 'https://koutavi.firebaseio.com',
    projectId: 'koutavi',
    storageBucket: 'koutavi.appspot.com',
    messagingSenderId: '813715320930',
    appId: '1:813715320930:web:65c97570bbdf693ce9213b',
    measurementId: 'G-QHL6BNZLR6'
  },
  algolia: {
    indexName: 'referrals',
    reviewIndexName: 'reviews',
    apiKey: '9d64907416677ba867740567b52fe0f4',
    appId: 'GZZ7IHE2S0',
  }
};