import { GoogleMap, LatLng, LatLngBounds } from '@agm/core/services/google-maps-types';
import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, Subject } from 'rxjs';
import { flatMap, map, pairwise, startWith } from 'rxjs/operators';
import { Resolution, SearchResultService, TasteUser } from '../search-result.service';
import * as R from 'ramda';

interface Marker {
  _geoloc: {
    lat: number;
    lng: number;
  }
  restaurant_name: string;
  dish_name: string;
  user: {
    path: string;
  }
  user_photo: string;
  username: string;
  text: string;
  score: number;
  point: Point;
  photo: string;
  active: boolean;
  inactive: boolean;
}

interface ClassedMarker {
  classes: string[];
  marker: Marker;
}

class Cluster {
  parent: Marker;
  children: Marker[] = [];
  fanned: Marker[] = [];
}

interface MapState {
  zoom: number;
  bounds: LatLngBounds;
  projection: any;
}

@Component({
  selector: 'app-taste-map',
  templateUrl: './taste-map.component.html',
  styleUrls: ['./taste-map.component.scss']
})
export class TasteMapComponent implements OnInit {
  clusters: Observable<Cluster[]>
  private mapInstance: GoogleMap;
  private generation = new Subject<number>();
  private activeMarkerSubject = new Subject<Marker>();
  activeMarker = this.activeMarkerSubject.asObservable().pipe(startWith(null));
  expanded: boolean = true;

  constructor(private results: SearchResultService, private route: ActivatedRoute, private router: Router) { }

  toggleExpand() {
    this.expanded = !this.expanded;
  }

  private get mapState(): MapState {
    if (!this.mapInstance) {
      return null;
    }
    const zoom = this.mapInstance.getZoom();
    const bounds = this.mapInstance.getBounds();
    const projection = (this.mapInstance as any).getProjection();
    return { zoom, bounds, projection };
  }
  private gen: number = 0;
  private get nextGeneration(): number { return this.gen++ };
  private updateGen(arg) {
    const next = this.nextGeneration;
    if (this.generation) {
      this.generation.next(next);
    }
  }
  user: Observable<{ user: TasteUser, exists: boolean }>;

  selectedUsername(username: string) {
    this.zoomOut();
    this.router.navigate(['/map', username]);
  }

  ngOnInit() {
    const _user = this.route.paramMap.pipe(
      flatMap(params => this.results.userFromUsername(params.get('username'))));
    this.user = _user.pipe(map(user => { return { user, exists: !!user }; }));
    const markers = this.user.pipe(flatMap(user => this.results.reviewIndex.search<Marker>(
      {
        hitsPerPage: 1000,
        tagFilters: ["review_marker"],
        query: user.exists ? user.user.ref.path : "",
      })),
      map((results) => results.hits),
    );
    const generation = this.generation.asObservable().pipe(startWith(this.nextGeneration));
    const updatedMarkers = markers.pipe(flatMap((markers) => generation.pipe(map((_) => {
      const mapState = this.mapState;
      return { markers, mapState };
    }))));
    this.clusters = combineLatest([this.activeMarker, updatedMarkers]).pipe(
      startWith(null),
      pairwise(),
      flatMap(async (pair) => {
        const before = pair[0];
        const after = pair[1];
        if (!after) {
          return;
        }
        const activeMarker = after[0];
        const mapState = after[1];
        if (!mapState.mapState) {
          return [];
        }
        const allMarkers = mapState.markers;
        allMarkers.sort((a, b) => a.active ? -1 : b.active ? 1 : b.score - a.score);
        const bounds = mapState.mapState.bounds;
        const zoom = mapState.mapState.zoom;
        if (!zoom) {
          return [];
        }
        const projection = mapState.mapState.projection;
        if (!projection) {
          return [];
        }
        if (activeMarker) {
          if (zoom < before[1].mapState.zoom) {
            this.unsetActive();
          } else if (!bounds.contains(activeMarker._geoloc as any)) {
            this.unsetActive();
          }
        }
        const sw = bounds.getSouthWest();
        const ne = bounds.getNorthEast();
        const center = bounds.getCenter();
        const distLat = center.lat() - sw.lat();
        const distLng = (center.lng() - sw.lng() + 360) % 360;
        bounds.extend({ lat: sw.lat() - distLat, lng: sw.lng() - distLng });
        bounds.extend({ lat: ne.lat() + distLat, lng: ne.lng() + distLng });
        const markers = allMarkers.filter((marker) => bounds.contains((marker._geoloc as any) as LatLng));
        markers.forEach(marker => {
          if (marker.point) {
            return;
          }
          marker.point = projection.fromLatLngToPoint({ lat: () => marker._geoloc.lat, lng: () => marker._geoloc.lng });
        });
        const scale = 1 << zoom;
        let clusterMap = new Map<Marker, Cluster>();
        const minDistance = Math.min(200, Math.max(160, (window.innerWidth - 380) / (120) * 60 + 160));
        const distance = (a: Marker, b: Marker): number => Math.pow(Math.pow((a.point.x - b.point.x) * scale, 2) + Math.pow((a.point.y - b.point.y) * scale, 2), 0.5);
        for (const marker of markers) {
          marker.active = marker === activeMarker;
          marker.inactive = (!!activeMarker) && !marker.active;
          let keep: boolean = true;
          for (const cluster of clusterMap.values()) {
            const clusterMarker = cluster.parent;
            const _distance = distance(marker, clusterMarker);
            if (_distance < minDistance) {
              keep = false;
              break;
            }
          }
          if (keep) {
            const cluster = new Cluster();
            cluster.parent = marker;
            clusterMap.set(marker, cluster);
          }
        }

        markers.filter(marker => !clusterMap.has(marker)).forEach(marker => {
          const cluster = R.reduce(R.minBy((cluster: Cluster) => distance(cluster.parent, marker)), clusterMap.values().next().value, Array.from(clusterMap.values()));
          cluster.children.push(marker);
          if (cluster.fanned.length < 2) {
            cluster.fanned.push(marker);
          }
        });
        return Array.from(clusterMap.values());
      }));
    this.onResize();
  }

  private unsetActive() {
    this.activeMarkerSubject.next(null);
  }

  mapLoad(map: GoogleMap) {
    this.mapInstance = map;
    map.addListener('projection_changed', () => this.updateGen('projection'));
    map.addListener('idle', () => this.updateGen('idle'));
    this.updateGen('map-load');
  }

  zoomChange(_) {
    this.updateGen('zoom-change');
  }

  mapClick(location: any) {
    this.unsetActive();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.updateGen('window')
  }

  markerPhoto(marker: Marker): Observable<String> {
    return this.results.resolve(marker.photo, marker.active ? Resolution.full : Resolution.thumbnail);
  }
  userPhoto(marker: Marker): Observable<String> {
    return this.results.resolve(marker.user_photo, marker.active ? Resolution.full : Resolution.thumbnail);
  }
  userProfilePhoto(user: TasteUser): Observable<string> {
    return this.results.resolve(user.photo);
  }
  markerTap(marker: Marker) {
    this.activeMarkerSubject.next(marker);
    const latLng = { lat: marker._geoloc.lat, lng: marker._geoloc.lng };
    this.mapInstance.panTo(latLng);
    return;
  }
  userRouterLink(username: string): string[] {
    if (!username) {
      return null;
    }
    return ['/map', username];
  }
  zoomOut() {
    this.mapInstance.setZoom(this.defaultZoom);
  }
  userPhotoTap(marker: Marker) {
    if (!!marker.username) {
      this.zoomOut();
      this.unsetActive();
    }
  }
  defaultZoom = 3;
  clusterTap(cluster: Cluster) {
    if (cluster.parent.active) {
      this.unsetActive();
      return;
    }
    if (cluster.children.length === 0 || this.mapInstance.getZoom() > 20) {
      this.markerTap(cluster.parent);
      return;
    }
    const markers = Array.from(cluster.children);
    markers.push(cluster.parent);
    const south = Math.min(...markers.map(m => m._geoloc.lat));
    const north = Math.max(...markers.map(m => m._geoloc.lat));
    const west = Math.min(...markers.map(m => m._geoloc.lng));
    const east = Math.max(...markers.map(m => m._geoloc.lng));
    const bounds = { south, north, east, west };
    this.mapInstance.fitBounds(bounds, { left: 15, right: 15, top: 0, bottom: 0 });
    this.updateGen('cluster');
  }
  getMapStyle() {
    return mapStyle;
  }

}

interface ProjLatLng {
  lat(): number;
  lng(): number;
}
interface Point {
  x: number;
  y: number;
}

interface Projection {
  fromLatLngToPoint(ll: ProjLatLng): Point;
  fromPointToLatLng(point: Point): ProjLatLng;
}

const mapStyle = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "administrative.neighborhood",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#bddc98"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f0e393"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#e6e6e6"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      },
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      },
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#c9c9c9"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#95bcf0"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }
];