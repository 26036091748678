import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SearchResultService } from '../search-result.service';
import { ReferralCache, SearchResult } from '../types';

@Component({
  selector: 'app-review-card',
  templateUrl: './review-card.component.html',
  styleUrls: ['./review-card.component.scss']
})
export class ReviewCardComponent implements OnInit {
  @Input()
  cache: ReferralCache;

  @Input()
  showOverlay: boolean = true;

  searchResult: SearchResult;

  constructor(private results: SearchResultService) { }

  ngOnInit(): void {
    this.searchResult = this.results.asSearchResult(this.cache);
  }

  get name(): string {
    return this.cache.user_username || this.cache.user_name;
  }

  get referralUrl(): string {
    const code = this.cache.objectID.split('/')[1];
    return `https://${environment.production ? '' : 'dev.'}trytaste.app/deal.html?code=${code}`;
  }

  get routerLink(): string[] {
    return this.showOverlay ? ['/referral', this.cache.reference.split('/')[1]] : null;
  }
}
