import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { BrowserModule } from '@angular/platform-browser';
import { AgmOverlays } from "agm-overlays";
import { NgAisModule } from 'angular-instantsearch';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { QRCodeModule } from 'angular2-qrcode';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { QrComponent } from './qr/qr.component';
import { ReviewCardComponent } from './review-card/review-card.component';
import { SearchComponent } from './search/search.component';
import { TasteMapComponent } from './taste-map/taste-map.component';
import { UserSearchComponent } from './user-search/user-search.component';


@NgModule({
  declarations: [
    AppComponent,
    ReviewCardComponent,
    QrComponent,
    SearchComponent,
    TasteMapComponent,
    UserSearchComponent,
  ],
  imports: [
    AngularFireAnalyticsModule,
    AgmOverlays,
    AngularFireAuthModule,
    AgmJsMarkerClustererModule,
    AngularFireFunctionsModule,
    AngularFireModule.initializeApp(environment.firebase, 'Taste Deals'),
    AngularFireStorageModule,
    AngularFirestoreModule,
    AppRoutingModule,
    BrowserModule,
    AutocompleteLibModule,
    NgAisModule,
    QRCodeModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCKxt0dAVGWyKybcsHQ76hXTfyz4uaf7HA'
    })
  ],
  providers: [
    GoogleMapsAPIWrapper,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
